import React from 'react'

import { Highlight } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Highlight"
    components={[{ component: Highlight }]}
  >
    <Section>
      <Playground>
        <Highlight keywords={['dolor', 'consec']}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi,
          ipsum?
        </Highlight>
      </Playground>
    </Section>
  </Content>
)

export default Page
